import React, { useState } from "react";
import "./Newsletter.css";

const Newsletter = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
  });

  /*
  const handleChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      [e.target.id]: e.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);
  };
*/
  return (
    <div className="newsletter section-p">
      <div className="container">
        <div className="workprocess-content">
          <div className="section-title">
            <h3 className="text-brown">
              Arya <span className="text-white">Ayurveda</span>
            </h3>
          </div>
          <div className="m-2 p-4">
            <p className="text-white m-4 p-4">
              <b>1. Ancient Wisdom & Modern Expertise:</b> Blending traditional
              Ayurveda with modern practices for holistic well-being.
            </p>

            <p className="text-white m-4 p-4">
              <b>2. Passion for Holistic Health:</b> Committed to helping
              individuals achieve balance of mind, body, and spirit.
            </p>

            <p className="text-white m-4 p-4">
              <b>3. Vision for Wellness:</b> Focused on empowering individuals
              on a holistic journey towards lasting health.
            </p>

            <p className="text-white m-4 p-4">
              <b>4. Experienced Practitioners:</b> Guided by practitioners
              trained in ancient Ayurvedic practices, addressing root causes of
              imbalance.
            </p>

            <p className="text-white m-4 p-4">
              <b>5. Tranquil Wellness Haven:</b> Tailored therapies and
              consultations designed to support your unique wellness needs.
            </p>

            <p className="text-white m-4 p-4">
              <b>6. Community & Connection:</b> A space for growth, where
              well-being extends beyond physical healing.
            </p>

            <p className="text-white m-4 p-4">
              <b>7. Journey to Holistic Living:</b> Supporting your
              transformation with Ayurveda, harmonizing tradition with modern
              life.
            </p>
          </div>
          {/* <form className='newsletter-form mx-auto' onSubmit={handleSubmit}>
                    <div className='form-elem'>
                        <input type = "text" value = {formData.name} className = "form-control" placeholder='Name' onChange={handleChange} id = "name" />
                    </div>
                    <div className='form-elem'>
                        <input type = "text" value = {formData.email} className = "form-control" placeholder='Email' onChange={handleChange} id = "email" />
                    </div>
                    <button type = "submit" className='bg-brown text-white submit-btn fw-3 fs-22'>Submit</button>
                </form> */}
        </div>
      </div>
    </div>
  );
};

export default Newsletter;
