import header_background from "../assets/images/header_background.png";
import fertility_treatments from "../assets/images/fertility_treatments.jpg";
import skin_disorders from "../assets/images/skin_disorders.jpg";
import panchakarma from "../assets/images/panchakarma.jpg";
import other_disorders from "../assets/images/other_disorders.jpg";
import ayurvedic_herbs from "../assets/images/ayurvedic_herbs.jpg";
import consultation from "../assets/images/consultation.jpg";
import hero_img from "../assets/images/hero-img.jpg";
import pattern_00 from "../assets/images/pattern_00.png";
import pattern_1 from "../assets/images/pattern_1.png";
import services_img_1 from "../assets/images/services_img_1.svg";
import services_img_2 from "../assets/images/services_img_2.svg";
import services_img_3 from "../assets/images/services_img_3.svg";
import services_img_4 from "../assets/images/services_img_4.svg";
import services_img_5 from "../assets/images/services_img_5.svg";
import services_img_6 from "../assets/images/services_img_6.svg";
import stat_img_1 from "../assets/images/stat_img_1.png";
import stat_img_2 from "../assets/images/stat_img_2.png";
import stat_img_3 from "../assets/images/stat_img_3.png";
import stat_img_4 from "../assets/images/stat_img_4.png";
import logo_img_1 from "../assets/images/logo_img_1.png";
import logo_img_2 from "../assets/images/logo_img_2.png";
import logo_img_3 from "../assets/images/logo_img_3.png";
import logo_img_4 from "../assets/images/logo_img_4.png";
import logo_img_5 from "../assets/images/logo_img_5.png";
import logo_img_6 from "../assets/images/logo_img_6.png";
import work_img_1 from "../assets/images/ayurveda_work_1.jpg";
import work_img_2 from "../assets/images/ayurveda_work_2.jpg";
import work_img_3 from "../assets/images/ayurveda_work_3.jpg";
import work_img_4 from "../assets/images/ayurveda_work_4.jpg";
import work_img_5 from "../assets/images/ayurveda_work_5.jpg";
import work_img_6 from "../assets/images/ayurveda_work_6.jpg";
import work_img_7 from "../assets/images/ayurveda_work_7.jpg";
import work_img_8 from "../assets/images/ayurveda_work_8.jpg";
import work_img_9 from "../assets/images/ayurveda_work_9.jpg";
import About_Alex from "../assets/images/About_Alex.jpg";
import About_doctor_1 from "../assets/images/doctor.jpg";
import subscribe_background from "../assets/images/subscribe_background.jpeg";

const images = {
  header_background,
  pattern_00,
  pattern_1,
  services_img_1,
  services_img_2,
  services_img_3,
  services_img_4,
  services_img_5,
  services_img_6,
  stat_img_1,
  stat_img_2,
  stat_img_3,
  stat_img_4,
  logo_img_1,
  logo_img_2,
  logo_img_3,
  logo_img_4,
  logo_img_5,
  logo_img_6,
  work_img_1,
  work_img_2,
  work_img_3,
  work_img_4,
  work_img_5,
  work_img_6,
  work_img_7,
  work_img_8,
  work_img_9,
  About_Alex,
  subscribe_background,
  hero_img,
  fertility_treatments,
  skin_disorders,
  panchakarma,
  other_disorders,
  ayurvedic_herbs,
  consultation,
  About_doctor_1,
};
export default images;
